import React, { useEffect } from 'react';
import LoadCard from '../components/loadCard';
import Footer from '../components/global/footer';
import Nav from '../components/global/nav';

const Load = () => {
	useEffect(() => {
		// document.body.classList.add('loading');
		document.body.setAttribute('data-layout-mode', 'horizontal');
		document.body.setAttribute('data-layout-color', 'light');
		document.body.setAttribute('data-layout-size', 'fluid');
		document.body.setAttribute('data-topbar-color', 'dark');
		document.body.setAttribute('data-leftbar-position', 'fixed');

		return () => {
			// document.body.classList.remove('loading');
		};
	}, []);

	return (
		<div>
			<Nav />
			<LoadCard />
			<Footer />
		</div>
	);
};

export default Load;
