import React, { useState, useEffect } from 'react';

const Nav = () => {
	const [token, setToken] = useState('');
	const [merchant, setMerchant] = useState({});

	useEffect(() => {
		try {
			// get the token and merchant from local storage
			const token = localStorage.getItem('token');
			const merchant = JSON.parse(localStorage.getItem('merchant'));

			if (token) {
				setToken(token);
			} else {
				window.location.href = '/#/login';
			}

			if (merchant) {
				setMerchant(merchant);
			}
		} catch (error) {
			console.log(`Error: ${error}`);

			// if cant find token or merchant, redirect to login
			window.location.href = '/#/login';
		}
	}, []);

	// // change between views when the user clicks on the links
	// const handleNav = (e) => {
	// 	e.preventDefault();
	// 	setView(e.target.id);
	// };

	const logout = async (e) => {
		e.preventDefault();
		// delete the token and merchant from local storage

		localStorage.removeItem('token');
		localStorage.removeItem('merchant');

		// redirect to login
		window.location.href = '/#/login';
	};
	return (
		<div id='wrapper'>
			<>
				<div
					className='navbar-custom'
					// style={{ marginTop: '-50px' }}
				>
					<div className='container-fluid'>
						<ul className='list-unstyled topnav-menu float-end mb-0'>
							<li className='dropdown notification-list topbar-dropdown'>
								<button
									className='nav-link dropdown-toggle nav-user me-0 waves-effect waves-light btn-reset'
									data-bs-toggle='dropdown'
									aria-haspopup='false'
									aria-expanded='false'
									style={{ backgroundColor: 'transparent', border: 'none' }}
								>
									<span className='pro-user-name ms-1'>
										{' '}
										{merchant.bus_name} <i className='mdi mdi-chevron-down'></i>{' '}
									</span>
								</button>
								<div className='dropdown-menu dropdown-menu-end profile-dropdown'>
									<div className='dropdown-header noti-title'>
										<h6 className='text-overflow m-0'>Welcome !</h6>
									</div>

									<a
										href='/#/profile'
										className='dropdown-item notify-item'
									>
										<i className='fe-user'></i>
										<span>My Account</span>
									</a>

									{/* <a
									href='auth-lock-screen.html'
									className='dropdown-item notify-item'
								>
									<i className='fe-lock'></i>
									<span>Lock Screen</span>
								</a> */}

									<div className='dropdown-divider'></div>

									<a
										href='/#/login'
										className='dropdown-item notify-item'
										onClick={(e) => logout(e)}
									>
										<i className='fe-log-out'></i>
										<span>Logout</span>
									</a>
								</div>
							</li>

							{/* <li className='dropdown notification-list'>
							<a
								href='/settings'
								className='nav-link right-bar-toggle waves-effect waves-light'
							>
								<i className='fe-settings noti-icon'></i>
							</a>
						</li> */}
						</ul>

						<div className='logo-box'>
							<a
								href='/'
								className='logo logo-light text-center'
							>
								<span className='logo-sm'>
									<img
										src='/images/Logo_Horizontal_light.svg'
										alt=''
										height='50'
									/>
								</span>
								<span className='logo-lg'>
									<img
										src='/images/Logo_Horizontal_light.svg'
										alt=''
										height='50'
									/>
								</span>
							</a>
							<a
								href='/'
								className='logo logo-dark text-center'
							>
								<span className='logo-sm'>
									<img
										src='/images/Logo_Horizontal.svg'
										alt=''
										height='22'
									/>
								</span>
								<span className='logo-lg'>
									<img
										src='/images/Logo_Horizontal.svg'
										alt=''
										height='50'
									/>
								</span>
							</a>
						</div>

						<ul className='list-unstyled topnav-menu topnav-menu-left mb-0'>
							<li>
								<a
									className='navbar-toggle nav-link'
									data-bs-toggle='collapse'
									data-bs-target='#topnav-menu-content'
								>
									<div className='lines'>
										<span></span>
										<span></span>
										<span></span>
									</div>
								</a>
							</li>
						</ul>

						<div className='clearfix'></div>
					</div>
				</div>
				<div className='topnav'>
					<div className='container-fluid'>
						<nav className='navbar navbar-light navbar-expand-lg topnav-menu'>
							<div
								className='collapse navbar-collapse'
								id='topnav-menu-content'
							>
								<ul className='navbar-nav'>
									<li className='nav-item dropdown'>
										<a
											className='nav-link arrow-none'
											id='dashboard'
											href='/'
											aria-haspopup='true'
											aria-expanded='false'
											style={{ backgroundColor: 'transparent', border: 'none' }}
										>
											<i className='mdi mdi-view-dashboard me-1'></i> Dashboard
										</a>
									</li>

									<li className='nav-item dropdown'>
										<a
											className='nav-link dropdown-toggle arrow-none'
											id='load-card'
											href='/#/load'
											aria-haspopup='true'
											aria-expanded='false'
											style={{ backgroundColor: 'transparent', border: 'none' }}
										>
											<i className='mdi mdi-invert-colors me-1'></i> Load Card
											{/* <div className='arrow-down'></div> */}
										</a>
									</li>

									<li className='nav-item dropdown'>
										<a
											className='nav-link dropdown-toggle arrow-none'
											id='redeem-card'
											href='/#/redeem'
											aria-haspopup='true'
											aria-expanded='false'
											style={{ backgroundColor: 'transparent', border: 'none' }}
										>
											<i className='fe-grid me-1'></i> Redeem Card
											{/* <div className='arrow-down'></div> */}
										</a>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</>
		</div>
	);
};

export default Nav;
