import { useEffect } from 'react';

const Error = () => {
	useEffect(() => {
		try {
			document.body.classList.add('authentication-bg', 'authentication-bg-pattern');
		} catch (error) {
			console.error('Error adding classes to body:', error);
		}
	}, []);
	return (
		<div className='account-pages mt-5 mb-5'>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-md-8 col-lg-6 col-xl-5'>
						<div className='text-center'>
							<a
								href='/'
								className='logo'
							>
								<img
									src='/images/Logo_Horizontal.svg'
									alt=''
									height='50'
									className='logo-light mx-auto'
								/>
							</a>
							<p className='text-muted mt-2 mb-4'>Responsive Admin Dashboard</p>
						</div>
						<div className='card'>
							<div className='card-body p-4'>
								<div className='text-center'>
									<h1 className='text-error'>500</h1>
									<h3 className='mt-3 mb-2'>Internal Server Error</h3>
									<p className='text-muted mb-3'>
										It seems that something went wrong on our end. We apologize for the inconvenience. Please try again
										later.
									</p>

									<a
										href='/'
										className='btn btn-danger waves-effect waves-light'
									>
										<i className='fas fa-home me-1'></i> Back to Home
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Error;
