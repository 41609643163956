import React, { useState } from 'react';
import { useEffect } from 'react';
import { APIPost } from '../utils/apicore';
import Notify from '../components/global/notify';
import { toast } from 'react-toastify';

function Login() {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [pin, setPIN] = useState('');
	// const [rememberMe, setRememberMe] = useState(false);

	// let toastId = React.useRef(null);

	useEffect(() => {
		document.body.classList.add('authentication-bg', 'authentication-bg-pattern');
	}, []);

	const handlePhoneNumberChange = (e) => {
		setPhoneNumber(e.target.value);
	};

	const handlePINChange = (e) => {
		setPIN(e.target.value);
	};

	// const handleRememberMeChange = (e) => {
	// 	setRememberMe(e.target.checked);
	// };

	const handleSubmit = async (e) => {
		e.preventDefault();
		// Add your login logic here
		// console.log('Submitting Login information');

		toast.info('Logging in...');

		const body = {
			phoneNumber: phoneNumber,
			w_pin: pin,
		};

		const results = await APIPost('auth/merchant/login', body, null);
		// console.log('Results:', results);
		if (!results.success) {
			// delete tost notification
			setTimeout(() => {
				toast.dismiss();
				toast.error(results.message);
			}, 1000);
			return;
		}

		toast.dismiss();
		toast.success('Login successful');

		setTimeout(() => {
			localStorage.setItem('token', results.token);
			localStorage.setItem('merchant', JSON.stringify(results.merchant));
			// localStorage.setItem('rememberMe', rememberMe);
			window.location.href = '/';
		}, 2000);
	};

	const handleSignUp = (e) => {
		e.preventDefault();
		window.location.href = '/#/signUp';
	};

	return (
		<>
			<Notify />
			<div className='account-pages my-5'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-md-8 col-lg-6 col-xl-4'>
							<div className='text-center'>
								<a href='/'>
									<img
										src='/images/Logo_Horizontal.svg'
										alt=''
										height='50'
										className='mx-auto'
									/>
								</a>
								<p className='text-muted mt-2 mb-4'>Loyalty Management System</p>
							</div>
							<div className='card'>
								<div className='card-body p-4'>
									<div className='text-center mb-4'>
										<h4 className='text-uppercase mt-0'>Sign In</h4>
									</div>

									<form action='#'>
										<div className='mb-3'>
											<label
												htmlFor='emailaddress'
												className='form-label'
											>
												Phone Number
											</label>
											<input
												className='form-control'
												type='email'
												id='emailaddress'
												required=''
												placeholder='Enter your phone number (+.....)'
												onChange={(e) => handlePhoneNumberChange(e)}
											/>
										</div>

										<div className='mb-3'>
											<label
												htmlFor='password'
												className='form-label'
											>
												Password
											</label>
											<input
												className='form-control'
												type='password'
												required=''
												id='pin'
												placeholder='Enter your PIN'
												onChange={(e) => handlePINChange(e)}
											/>
										</div>

										<div className='mb-3 d-grid text-center'>
											<button
												className='btn btn-primary'
												type='submit'
												onClick={(e) => handleSubmit(e)}
											>
												{' '}
												Log In{' '}
											</button>
										</div>
									</form>
								</div>
							</div>

							<div className='row mt-3'>
								<div className='col-12 text-center'>
									<p>
										{' '}
										<a
											href='/#/forgotPin'
											className='text-muted ms-1'
										>
											<i className='fa fa-lock me-1'></i>Forgot your password?
										</a>
									</p>
									<p className='text-muted'>
										Don't have an account?{' '}
										<a
											href='/#/signUp'
											className='text-dark ms-1'
										>
											<b onClick={(e) => handleSignUp(e)}>Sign Up</b>
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
