import { useEffect, useState } from 'react';
import { APIGet } from '../utils/apicore';
import Notify from './global/notify';
import { toast } from 'react-toastify';

const Dashboard = () => {
	const [token, setToken] = useState('');
	const [merchant, setMerchant] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [todayTransactions, setTodayTransactions] = useState([]);
	const [todayIssuedCards, setTodayIssuedCards] = useState([]);
	const [yesterdayIssuedCards, setYesterdayIssuedCards] = useState([]);
	const [cards, setCards] = useState([]);
	const [searchDetails, setSearchDetails] = useState('');
	// const [searchType, setSearchType] = useState('voucherCode');
	const [activeTab, setActiveTab] = useState('transactions');
	const [showModal, setShowModal] = useState(false);
	const [reportMode, setReportMode] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	// console.log('Show Modal:', showModal);

	const dateStructure = (date) => {
		const dd = String(today.getDate()).padStart(2, '0');
		const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		const yyyy = today.getFullYear();
		return `${yyyy}-${mm}-${dd}`;
	};

	// filter transactions for today
	const today = new Date();
	const todayDate = dateStructure(today);

	//  yesterday date
	const yesterday = today.setDate(today.getDate() - 1);
	const yesterdayDate = dateStructure(yesterday);

	useEffect(() => {
		try {
			// toast.info('Loading. Please wait...');
			setToken(localStorage.getItem('token'));
			setMerchant(JSON.parse(localStorage.getItem('merchant')));

			if (token) {
				setToken(token);

				if (!loaded) {
					APIGet('transactions/merchant', 'sort=-createdAt', token)
						.then((response) => {
							// console.log('Response: ', response);
							if (response.error) {
								toast.dismiss();
								toast.error(response.error);
							}

							if (response.data) setTransactions(response.data);
						})
						.catch((error) => {
							// console.log(`Error: ${error}`);
							// console.log();
						});
					// get cards
					APIGet('cards/merchant', '', token)
						.then((response) => {
							// console.log('Response: ', response);
							if (response.error) {
								toast.dismiss();
								toast.error(response.error);
							}

							if (response.data) setCards(response.data);
						})
						.catch((error) => {
							// console.log(`Error: ${error}`);
						});
					setLoaded(true);
				}
				// if (loaded) {
				// 	setTimeout(() => {
				// 		toast.dismiss();
				// 		toast.success('Data Loaded');
				// 	}, 1000);
				// }
			}

			if (merchant) {
				setMerchant(merchant);
			}
		} catch (error) {
			// console.log(`Error: ${error}`);

			// if cant find token or merchant, redirect to login
			window.location.href = '/#/login';
		}

		const tc = cards.filter((card) => {
			const cardDate = new Date(card.createdAt).toISOString().split('T')[0];
			return cardDate === todayDate;
		});
		// filter by those who
		setTodayIssuedCards(tc);
		const tt = transactions.filter((transaction) => {
			const transactionDate = new Date(transaction.createdAt).toISOString().split('T')[0];
			// console.log('Transaction Date:', transactionDate);
			// console.log('Today Date:', todayDate);
			return transactionDate === todayDate;
		});

		const yc = cards.filter((card) => {
			const cardDate = new Date(card.createdAt).toISOString().split('T')[0];
			return cardDate === yesterdayDate;
		});
		setYesterdayIssuedCards(yc);
		setTodayTransactions(tt);
	}, [cards, loaded, merchant, todayDate, token, transactions, yesterdayDate]);

	const handleChange = (event) => {
		// console.log('Event:', event.target.id);
		switch (event.target.id) {
			case 'searchType':
				// setSearchType(event.target.value);
				break;
			case 'searchDetails':
				setSearchDetails(event.target.value);
				break;
			case 'activeTab':
				setActiveTab(event.target.value);
				break;
			default:
				break;
		}
	};

	const handleSearch = async (e) => {
		e.preventDefault();

		if (searchDetails === '') {
			toast.error('Please enter search details');
			return;
		}

		// form the search query
		// let query = '';
		// switch (searchType) {
		// 	case 'phoneNumber':
		// 		query = `phoneNumber=${searchDetails}`;
		// 		break;
		// 	case 'transactionCode':
		// 		query = `transactionCode=${searchDetails}`;
		// 		break;
		// 	default:
		// 		query = `voucherCode=${searchDetails}`;
		// 		break;
		// }

		// search for the transaction
		APIGet(
			`${activeTab === 'transactions' ? 'transactions' : 'cards'}/merchant/${searchDetails}`,
			`sort=-createdAt`,
			token
		)
			.then((response) => {
				console.log('Response: ', response);
				if (response.error) {
					toast.dismiss();
					toast.error(response.error);
				}

				// console.log('Response: ', response.data);
				if (activeTab === 'transactions') setTransactions(response.data);
				else setCards(response.data);
			})
			.catch((error) => {
				// console.log(`Error: ${error}`);
				// console.log();
			});
	};

	const cardToCSV = (data) => {
		// Create CSV headers
		const headers = ['#', 'Phone Number', 'Card', 'Amount', 'Created At', 'Last Transaction', 'Status'];
		// Map data to CSV rows
		const rows = data.map((card, index) => [
			index + 1,
			card.user?.phoneNumber || 'N/A',
			card.voucherCode || '_',
			`${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 })
				.format(card.amount ?? 0)
				.replace(',', '')}`,
			card.createdAt ? card.createdAt : 'Unknown Date',
			card.updatedAt ? card.updatedAt : 'Unknown Date',
			card.status || 'Unknown Status',
		]);
		// Combine headers and rows, and join each row with commas
		const csvContent = [headers, ...rows].map((e) => e.join(',')).join('\n');
		return csvContent;
	};

	const transToCSV = (data) => {
		// Create CSV headers
		const headers = ['#', 'Phone Number', 'Card', 'Date', 'Amount', 'Type', 'Transaction Code'];
		// Map data to CSV rows

		const rows = data.map((transaction, index) => [
			index + 1,
			transaction.user?.phoneNumber || 'N/A',
			transaction.voucherCode || '_',
			transaction.createdAt ? transaction.createdAt : 'Unknown Date',
			`${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 })
				.format(transaction.amount ?? 0)
				.replace(',', '')}`,

			transaction.transactionType || 'Unknown Type',
			transaction.transactionCode || 'N/A',
		]);
		// Combine headers and rows, and join each row with commas
		const csvContent = [headers, ...rows].map((e) => e.join(',')).join('\n');
		return csvContent;
	};

	const downloadCSV = (csvContent, fileName = 'data.csv') => {
		// Create a Blob with the CSV content
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		// Create a link to download the Blob
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleDownloadReport = async (e) => {
		e.preventDefault();

		toast.dismiss();
		toast.info('Creating Reports, Please wait...');

		let query = '';

		if (startDate !== '') {
			query = `createdAt[gte]=${startDate}`;
		}

		if (endDate !== '') {
			query += `createdAt[lte]=${endDate}`;
		}

		// Get all transactions from database
		if (reportMode === 'transactions') {
			handleDownloadTransactions(query);
		}

		// Get all cards from database
		if (reportMode === 'cards') {
			handleDownloadCards(query);
		}

		// Get all cards and transactions from database
		if (reportMode === '') {
			handleDownloadTransactions(query);
			handleDownloadCards(query);
		}
	};

	const handleDownloadTransactions = async (query) => {
		const transactions = await APIGet('transactions/merchant', `limit=1000000&${query}`, token);
		const csvContent = transToCSV(transactions.data);
		downloadCSV(csvContent, 'TransactionsData.csv');
		// toast.dismiss();
		toast.success('Transactions Report Created Successfully');
		return;
	};

	const handleDownloadCards = async (query) => {
		const cards = await APIGet('cards/merchant', `limit=1000000&${query}`, token);
		const csvContent = cardToCSV(cards.data);
		downloadCSV(csvContent, 'CardsData.csv');
		// toast.dismiss();
		toast.success('Cards Report Created Successfully');
		return;
	};

	let cardsIncrease = 0;
	if (yesterdayIssuedCards.length !== 0)
		cardsIncrease = ((todayIssuedCards.length - yesterdayIssuedCards.length) / yesterdayIssuedCards.length) * 100;

	// check the card load from today and compare to card loads from yesterday
	const cly = todayTransactions.filter((transaction) => transaction.transactionType === 'CardLoad');
	let percentofTransactions = 0;
	if (todayTransactions.length > 0) percentofTransactions = (cly.length / todayTransactions.length) * 100;

	// console.log('Percent of Transactions:', percentofTransactions);

	return (
		<>
			<Notify />
			{showModal && (
				<div
					className={`modal fade show`}
					id='centermodal'
					tabIndex='-1'
					style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
					aria-modal='true'
					role='dialog'
				>
					<div className='modal-dialog modal-dialog-centered'>
						<div className='modal-content'>
							<div className='modal-header mb-3'>
								<h4
									className='modal-title'
									id='myCenterModalLabel'
								>
									Report Details
								</h4>
								{/* <p className='mt-3'>Enter the details of the report you want</p> */}
								<button
									type='button'
									className='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
									onClick={() => setShowModal(false)}
								></button>
							</div>
							<form
								className='px-3'
								action='#'
							>
								<div className='mb-3'>
									<label className='form-label'>Type</label>
									<select
										className='form-select'
										id='reportMode'
										value={reportMode}
										onChange={(e) => setReportMode(e.target.value)}
									>
										<option value=''>All Reports</option>
										<option value='transactions'>Transactions</option>
										<option value='cards'>Cards</option>
									</select>
								</div>

								<div className='mb-3'>
									<label className='form-label'>From</label>
									<input
										className='form-control'
										type='date'
										id='startDate'
										placeholder='Pick Start Date'
										onChange={(e) => setStartDate(e.target.value)}
									/>
								</div>

								<div className='mb-3'>
									<label className='form-label'>To</label>
									<input
										className='form-control'
										type='date'
										id='endDate'
										placeholder='Pick End Date'
										onChange={(e) => setEndDate(e.target.value)}
									/>
								</div>

								<div className='mb-3 text-center'>
									<button
										className='btn btn-primary'
										type='submit'
										onClick={(e) => handleDownloadReport(e)}
									>
										Download Report
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}
			<div className='content-page'>
				<div className='content'>
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='page-title-box'>
									<h4 className='page-title'>Merchant Dashboard</h4>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-xl-4 col-md-6'>
								<div
									className='card'
									// style={{ opacity: loaded ? 0 : 1, transition: 'opacity 1s' }}
								>
									<div className='card-body'>
										<h4 className='header-title mt-0 mb-4'>Transactions Today</h4>

										<div className='widget-chart-1'>
											<div
												className='widget-chart-box-1 float-start'
												dir='ltr'
											></div>

											<div className='widget-detail-1 text-end'>
												<h2 className='fw-normal pt-2 mb-1'>{todayTransactions.length}</h2>
												<p className='text-muted mb-1'>Today</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-xl-4 col-md-6'>
								<div
									className='card'
									// style={{ opacity: loaded ? 0 : 1, transition: 'opacity 1s' }}
								>
									<div className='card-body'>
										<h4 className='header-title mt-0 mb-3'>Cards Issued Today</h4>

										<div className='widget-box-2'>
											<div className='widget-detail-2 text-end'>
												<span
													className={`badge ${
														cardsIncrease > 0 ? 'bg-success' : 'bg-danger'
													} rounded-pill float-start mt-3`}
												>
													{cardsIncrease.toFixed(0)}{' '}
													<i className={`mdi ${cardsIncrease > 0 ? 'mdi-trending-up' : 'mdi-trending-down'}`}></i>
												</span>
												<h2 className='fw-normal mb-1'>{todayIssuedCards.length}</h2>
												<p className='text-muted mb-3'>Cards Issued</p>
											</div>
											<div
												className={`progress ${
													cardsIncrease > 0 ? 'progress-bar-alt-success' : 'progress-bar-alt-danger'
												} progress-sm`}
											>
												<div
													className='progress-bar bg-success'
													role='progressbar'
													aria-valuenow='1'
													aria-valuemin='0'
													aria-valuemax='100'
													style={{ width: `${Math.abs(cardsIncrease)}` }}
												></div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-xl-4 col-md-6'>
								<div className='card'>
									<div className='card-body'>
										<h4 className='header-title mt-0 mb-3'>Card Loads Today</h4>

										<div className='widget-box-2'>
											<div className='widget-detail-2 text-end'>
												<span
													className={`badge ${
														percentofTransactions > 0 ? 'bg-success' : 'bg-danger'
													} rounded-pill float-start mt-3`}
												>
													{percentofTransactions.toFixed(0)}
													<i
														className={`mdi ${percentofTransactions > 0 ? 'mdi-trending-up' : 'mdi-trending-down'}`}
													></i>
												</span>
												<h2 className='fw-normal mb-1'>{cly.length}</h2>
												<p className='text-muted mb-3'>Overall Transactions</p>
											</div>
											<div
												className={`progress ${
													percentofTransactions > 0 ? 'progress-bar-alt-success' : 'progress-bar-alt-danger'
												} progress-sm`}
											>
												<div
													className='progress-bar bg-danger'
													role='progressbar'
													aria-valuenow='20'
													aria-valuemin='0'
													aria-valuemax='100'
													style={{ width: `${Math.abs(percentofTransactions)}` }}
												></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='mb-3 row'>
							<div className='col-4'>
								<div className='input-group'>
									<button
										className={`btn waves-effect waves-light col-6 ${
											activeTab === 'transactions' ? 'btn-primary' : 'btn-outline-primary'
										}`}
										type='button'
										onClick={() => setActiveTab('transactions')}
									>
										Transactions
									</button>

									<button
										className={`btn waves-effect waves-light col-6 ${
											activeTab === 'cards' ? 'btn-primary' : 'btn-outline-primary'
										}`}
										type='button'
										onClick={() => setActiveTab('cards')}
									>
										Cards
									</button>
								</div>
							</div>
							<div className='col-6'>
								{/* <label className='form-label'>Buttons</label> */}
								<div className='input-group'>
									{/* <select
										className='btn input-group-text btn-primary waves-effect waves-light dropdown-toggle col-3'
										type='button'
										id='searchType'
										onChange={handleChange}
									>
										<option value='phoneNumber'>Phone Number</option>
										<option value='transactionCode'>Transaction Code</option>
										<option value='card'>Card Number</option>
									</select> */}
									<input
										type='text'
										id='searchDetails'
										className='form-control col-6'
										placeholder='Enter loyalty card number'
										onChange={handleChange}
									/>
									<button
										className='btn input-group-text btn-soft-primary waves-effect waves-light col-3'
										type='button'
										onClick={handleSearch}
									>
										Search
									</button>
								</div>
							</div>
							<div className='col-2'>
								<button
									className='btn btn-primary waves-effect waves-light col-12'
									type='button'
									onClick={() => setShowModal(true)}
								>
									Download Full Report
								</button>
							</div>
						</div>

						<div className='row'>
							<div className='col-xl-12'>
								{activeTab === 'transactions' && (
									<div className='card'>
										<div className='card-body'>
											<div className='table-responsive'>
												{transactions.length > 0 ? (
													<table className='table table-hover mb-0'>
														<thead>
															<tr>
																<th>#</th>
																<th>Phone Number</th>
																<th>Card</th>
																<th>Date</th>
																<th>Amount</th>
																<th>Transaction Code</th>
																<th>Type</th>
															</tr>
														</thead>
														<tbody>
															{transactions.map((transaction, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{transaction.user?.phoneNumber || 'N/A'}</td>
																	<td>{transaction.voucherCode || '_'}</td>
																	<td>
																		{transaction.createdAt
																			? new Date(transaction.createdAt).toLocaleString()
																			: 'Unknown Date'}
																	</td>
																	<td>
																		{transaction.currency}
																		{new Intl.NumberFormat('en-US', {
																			style: 'decimal',
																			minimumFractionDigits: 2,
																		}).format(transaction.amount ?? 0)}
																	</td>
																	<td>{transaction.transactionCode || 'N/A'}</td>
																	<td>
																		<span
																			className={`badge ${
																				transaction.transactionType === 'CardLoad' ? 'bg-success' : 'bg-info'
																			}`}
																		>
																			{transaction.transactionType || 'Unknown Type'}
																		</span>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												) : (
													<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<p style={{ fontSize: '25px' }}>{`${loaded ? 'Nothing to show' : 'Loading...'}`}</p>
													</div>
												)}
											</div>
										</div>
									</div>
								)}
								{activeTab === 'cards' && (
									<div className='card'>
										<div className='card-body'>
											<div className='table-responsive'>
												{cards.length > 0 ? (
													<table className='table table-hover mb-0'>
														<thead>
															<tr>
																<th>#</th>
																<th>Phone Number</th>
																<th>Card</th>
																<th>Amount</th>
																<th>Created At</th>
																<th>Last Transaction</th>
																<th>Status</th>
															</tr>
														</thead>
														<tbody>
															{cards.map((card, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{card.user?.phoneNumber || 'N/A'}</td>
																	<td>{card.voucherCode || '_'}</td>
																	<td>
																		{card.currency}
																		{new Intl.NumberFormat('en-US', {
																			style: 'decimal',
																			minimumFractionDigits: 2,
																		}).format(card.amount ?? 0)}
																	</td>
																	<td>{card.createdAt ? new Date(card.createdAt).toLocaleString() : 'Unknown Date'}</td>
																	<td>{card.updatedAt ? new Date(card.updatedAt).toLocaleString() : 'Unknown Date'}</td>
																	<td>
																		<span className={`badge ${card.status === 'Active' ? 'bg-success' : 'bg-info'}`}>
																			{card.status || 'Unknown Status'}
																		</span>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												) : (
													<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<p style={{ fontSize: '25px' }}>{`${loaded ? 'Nothing to show' : 'Loading...'}`}</p>
													</div>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>

						<div className='mb-3 d-flex justify-content-center'></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
