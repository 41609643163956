const Footer = () => {
	return (
		<footer className='footer'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-md-6'>
						{new Date().getFullYear()} &copy; Admin Dashboard <a href='/'>Solaxis</a>
					</div>
					<div className='col-md-6'>
						<div className='text-md-end footer-links d-none d-sm-block'>
							<a href='https://www.sungate.co.za/'>About Us</a>
							<a href='https://www.sungate.co.za/contact-us/'>Help</a>
							<a href='https://www.sungate.co.za/contact-us/'>Contact Us</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
