import React, { useState, useEffect } from 'react';
import Notify from '../components/global/notify';
import { APIPut, APIGet } from '../utils/apicore';
import { toast } from 'react-toastify';

function SignUp() {
	const [loaded, setLoaded] = useState(false);
	const [merchant, setMerchant] = useState({});
	const [token, setToken] = useState('');
	const [bus_name, setbus_name] = useState('');
	const [id_number, setId_number] = useState('');
	const [bus_type, setBus_type] = useState('');
	const [address, setAddress] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [w_pin, setW_pin] = useState('');

	useEffect(() => {
		document.body.classList.add('authentication-bg', 'authentication-bg-pattern');
		if (!loaded) {
			try {
				// get the token from local storage
				const token = localStorage.getItem('token');
				setToken(token);

				// get the merchant from local storage
				const merchant = JSON.parse(localStorage.getItem('merchant'));

				// if token is not found, redirect to login
				if (!token || token === '' || token === null || !merchant) {
					window.location.href = '/#/login';
				}
			} catch (error) {
				console.error(error);
			}

			// get merchant info

			console.log(`Token while Loading: ${token}`);
			APIGet(`auth/merchant/getMe`, '', token)
				.then((response) => {
					console.log('Response: ', response.success);
					if (!response.success) {
						toast.error(response.message);
					}

					setMerchant(response.data);
					setbus_name(response.data.bus_name);
					setId_number(response.data.kyc.id_number);
					setBus_type(response.data.bus_type);
					setAddress(response.data.address);
					setEmail(response.data.email);
					setPhoneNumber(response.data.phoneNumber);

					setToken(token);
					setLoaded(true);
					setTimeout(() => {
						toast.dismiss();
						toast.success('Merchant Loaded');
					}, 1000);
				})
				.catch((error) => {
					console.log(`Error: ${error}`);
					console.log();
				});
		}
	}, [loaded, merchant, token]);

	console.log('Merchant:', merchant);

	const handleChange = (event) => {
		switch (event.target.id) {
			case 'bus_name':
				setbus_name(event.target.value);
				break;
			case 'id_number':
				setId_number(event.target.value);
				break;
			case 'bus_type':
				setBus_type(event.target.value);
				break;
			case 'address':
				setAddress(event.target.value);
				break;
			case 'email':
				setEmail(event.target.value);
				break;
			case 'phoneNumber':
				setPhoneNumber(event.target.value);
				break;
			case 'w_pin':
				setW_pin(event.target.value);
				break;

			default:
				break;
		}
	};

	const handleSubmit = async (e) => {
		let message = '';
		e.preventDefault();
		// Add your login logic here

		if (!/^\S+@\S+\.\S+$/.test(email)) {
			message += 'Invalid email address';
		}

		// check all inputs are filled
		if (bus_name === '' || bus_type === '' || address === '' || email === '') {
			message += 'Please fill all fields';
		}

		if (message !== '') {
			setTimeout(() => {
				toast.dismiss();
				toast.error(message);
			}, 1000);
			return;
		}

		// send to the API
		const body = {
			bus_name,
			bus_type,
			address,
			email,
		};

		const result = await APIPut(`merchants/${merchant.phoneNumber}`, body, token);
		console.log('Results:', result);
		if (!result.success || result.error) {
			setTimeout(() => {
				toast.dismiss();
				toast.error(result.error || result.message);
			}, 1000);
			return;
		}

		setTimeout(() => {
			toast.dismiss();
			toast.success('Profile Updated Successfully');
		}, 1000);
	};

	return (
		<>
			<Notify />

			<div className='account-pages mt-5 mb-5'>
				<div className='container'>
					<button
						className='btn btn-outline-primary'
						onClick={() => window.history.back()}
					>
						Back
					</button>
					<div className='row justify-content-center'>
						<div className='col-md-8 col-lg-6 col-xl-4'>
							<div className='text-center'>
								<a href='/'>
									<img
										src='/images/Logo_Horizontal.svg'
										alt=''
										height='50'
										className='mx-auto'
									/>
								</a>
								<p className='text-muted mt-2 mb-4'>Loyalty Management System</p>
							</div>
							<div className='card'>
								<div className='card-body p-4'>
									<div className='text-center mb-4'>
										<h4 className='text-uppercase mt-0'>Merchant Profile</h4>
									</div>

									<form action='#'>
										<div className='mb-3'>
											<label className='form-label'>Business Name</label>
											<input
												className='form-control'
												type='text'
												id='bus_name'
												placeholder='Enter your business name'
												required
												value={bus_name}
												onChange={handleChange}
											/>
										</div>
										<div className='mb-3'>
											<label className='form-label'>ID Number</label>
											<input
												className='form-control'
												type='text'
												id='id_number'
												placeholder='Enter your ID Number'
												required
												value={id_number}
												onChange={handleChange}
												disabled={true}
											/>
										</div>
										<div className='mb-3'>
											<label className='form-label'>Business Type</label>
											<select
												className='form-control'
												id='bus_type'
												required
												value={bus_type}
												onChange={handleChange}
											>
												<option value=''>Select Business Type</option>
												<option value='Retail'>Retail</option>
												<option value='Restaurants/Fast Food'>Restaurants/Fast Food</option>
												<option value='Service Provider'>Service Provider</option>
												<option value='Transport'>Transport</option>
												<option value='Other'>Other</option>
											</select>
										</div>
										<div className='mb-3'>
											<label className='form-label'>Address</label>
											<textarea
												className='form-control'
												type='text'
												id='address'
												placeholder='Enter your address'
												required
												value={address}
												onChange={handleChange}
											/>
										</div>
										<div className='mb-3'>
											<label className='form-label'>Email address</label>
											<input
												className='form-control'
												type='email'
												id='email'
												required
												placeholder='Enter your email'
												value={email}
												onChange={handleChange}
											/>
										</div>
										<div className='mb-3'>
											<label className='form-label'>Phone Number</label>
											<input
												className='form-control'
												type='tel'
												id='phoneNumber'
												required
												placeholder='Enter your phoneNumber (+.....)'
												value={phoneNumber}
												onChange={handleChange}
												disabled={true}
											/>
										</div>

										<div className='mb-3 text-center d-grid'>
											<button
												className='btn btn-primary'
												type='submit'
												onClick={(e) => handleSubmit(e)}
											>
												{' '}
												Update Profile{' '}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SignUp;
