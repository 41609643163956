import React, { useEffect } from 'react';
import ErrorBoundary from './errorBoundary';
import { createRoot } from 'react-dom/client';
import { HashRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import Notify from './components/global/notify';
import { toast } from 'react-toastify';

// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Merchant from './pages/merchantDashboard';
import Load from './pages/loadCard';
import Redeem from './pages/redeemCard';
import Login from './pages/login';
import SignUp from './pages/signUp';
import Error404 from './pages/404';
import ForgotPin from './pages/forgotPin';
import Account from './pages/account';

// const root = createRoot(document.getElementById('root'));
const root = createRoot(document.getElementById('root'));
const TIMEOUT_DURATION = 15 * 60 * 1000; // 1 hour in milliseconds

const App = () => {
	useEffect(() => {
		console.log(window.location.pathname);
		const handleWindowClose = (event) => {
			if (event.target === window) {
				// Clear specific localStorage items
				localStorage.removeItem('token');
				localStorage.removeItem('merchant');
				localStorage.removeItem('rememberMe');

				// Prompt the user before closing the window
				event.preventDefault();
				event.returnValue = '';
			}
		};
		let activityTimeout;

		const onActivity = () => {
			clearTimeout(activityTimeout);
			activityTimeout = setTimeout(onTimeout, TIMEOUT_DURATION);
		};

		const onTimeout = () => {
			// Actions to perform after timeout, e.g., log the user out
			toast.info('User has been inactive for too long. Logging out...');
			// Actions to perform after timeout, e.g., log the user out
			setTimeout(() => {
				// Clear user session, for example:
				localStorage.removeItem('token');
				localStorage.removeItem('merchant');
				localStorage.removeItem('rememberMe');
				// Redirect to login page or show a logout message
				// Assuming you're using React Router for navigation
				window.location.href = '/#/login';
			}, 3000);
		};

		// Initialize the activity timeout on component mount

		activityTimeout = setTimeout(onTimeout, TIMEOUT_DURATION);

		// Define user activities to listen for
		const activities = ['mousemove', 'keydown', 'scroll', 'click'];

		// Add event listeners for the defined activities
		activities.forEach((activity) => window.addEventListener(activity, onActivity));

		// Add event listener for beforeunload
		window.addEventListener('beforeunload', handleWindowClose);

		// Cleanup function to remove the event listener
		return () => {
			clearTimeout(activityTimeout);
			activities.forEach((activity) => window.removeEventListener(activity, onActivity));
			window.removeEventListener('beforeunload', handleWindowClose);
		};
	}, []);

	return (
		<>
			<Notify />
			<HashRouter>
				<ErrorBoundary>
					<Routes>
						<Route
							path='/'
							element={<Merchant />}
						/>
						<Route
							path='/load'
							element={<Load />}
						/>
						<Route
							path='/redeem'
							element={<Redeem />}
						/>
						<Route
							path='/login'
							element={<Login />}
						/>
						<Route
							path='/signUp'
							element={<SignUp />}
						/>
						<Route
							path='/forgotPin'
							element={<ForgotPin />}
						/>
						<Route
							path='/profile'
							element={<Account />}
						/>

						<Route
							path='*'
							element={<Error404 />}
						/>
					</Routes>
				</ErrorBoundary>
			</HashRouter>
		</>
	);
};

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// root.render(
// 	<React.StrictMode>
// 		<HashRouter>
// 			<ErrorBoundary>
// 				<Routes>
// 					<Route
// 						path='/'
// 						element={<Merchant />}
// 					/>
// 					<Route
// 						path='/load'
// 						element={<Load />}
// 					/>
// 					<Route
// 						path='/redeem'
// 						element={<Redeem />}
// 					/>
// 					<Route
// 						path='/login'
// 						element={<Login />}
// 					/>
// 					<Route
// 						path='/signUp'
// 						element={<SignUp />}
// 					/>
// 					<Route
// 						path='/forgotPin'
// 						element={<ForgotPin />}
// 					/>
// 					<Route
// 						path='/profile'
// 						element={<Account />}
// 					/>

// 					<Route
// 						path='*'
// 						element={<Error404 />}
// 					/>
// 				</Routes>
// 			</ErrorBoundary>
// 		</HashRouter>
// 	</React.StrictMode>
// );

reportWebVitals();
